import {Box, Button, Card, Paper, Radio, Text, Title} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useContext} from "react";
import {OrderDetailsContext} from "./context";
import {Trans, useTranslation} from "react-i18next";

function RejectReasonSelector({form}) {
    const order = useContext(OrderDetailsContext);
    const { t } = useTranslation();

    const serviceCenterInfo = (
        <Paper p="sm" shadow="none" ml={30} mt="xs" radius="md"
               sx={(theme) => ({backgroundColor: theme.colors.gray[1]})}>
            <Title order={6}>{t('address')}</Title>
            <Text size="sm" mb="sm">{order.brand.address}</Text>

            <Title order={6}>{t('business_hours')}</Title>
            <Text size="sm" mb="sm">{order.brand.business_hours}</Text>

            <Title order={6}>{t('contact')}</Title>
            <Text size="sm">
                {/*<Trans i18nKey={"reject_collect_time"} />*/}
                {/*<Trans i18nKey={"reject_collect_time"} />*/}

                (852)<a href="tel:85230659666" style={{ textDecoration: "underline", color: '#6a88af' }}>3065 9666</a>(HK)<br/>
                (853)<a href="tel:85368258567" style={{ textDecoration: "underline", color: '#6a88af' }}>6825 8567</a>(Macau)
            </Text>
        </Paper>
    );

    return (
        <Radio.Group orientation="vertical"
                     spacing="xs" pb="xs" required
                     {...form.getInputProps('reject_handling_method')}>
            <Box mb="md">
                <Radio value="collect" label={<div dangerouslySetInnerHTML={{ __html: t('reject_collect') }}></div>} />

                {/*<Paper p="sm" shadow="none" ml={30} mt="xs" radius="md" sx={(theme) => ({backgroundColor: theme.colors.gray[1]})}>*/}
                {/*    <Title order={6}>{t('contact')}</Title>*/}
                {/*    <Text size="sm">*/}
                {/*        <a href="tel:85230659666" >(852)3065 9666(hk)</a><br/>*/}
                {/*    </Text>*/}
                {/*</Paper>*/}

                {form.values.reject_handling_method === 'collect' ? serviceCenterInfo : <></>}
            </Box>

            <Radio value="scrap" label={<div dangerouslySetInnerHTML={{ __html: t('reject_scrap') }}></div>} mb="md" />
        </Radio.Group>
    );
}

export default function ResponseRejectForm({onSubmit, submitting}) {
    const { t } = useTranslation();

    const form = useForm({
        initialValues: {
            reject_handling_method: 'collect',
        },
        validate: {
            reject_handling_method: (value) => {
                return !value ? t('error_messages.reject_method_missing') : null;
            }
        }
    });

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit({action: 'reject', ...values}))}>
            <Box>
                <Text size="sm" mb="xs">{t('ask_reject_description')}</Text>

                <Card mb="lg" py="xs">
                    <RejectReasonSelector form={form} />
                </Card>

                <Button fullWidth type="submit" loading={submitting}>{t('submit')}</Button>
            </Box>
        </form>
    );
}