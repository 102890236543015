import {ErrorAlert} from "./error";
import {Card, Skeleton} from "@mantine/core";
import api from "../../lib/api";
import useSWR from "swr";
import StripeElement from "./payments/stripe";
import {useParams} from "react-router-dom";
import CyberSourceForm from "./payments/cybersource";
import OfflinePaymentInfo from "./payments/offline";

const fetchData = async function (key, orderId, token, method) {
    const res = await api.post(`/api/order/${orderId}/${token}/pay`, {
        payment_method: method,
    });

    return res.data;
};

function Loading() {
    return (
        <Card>
            <Skeleton width={300} height={30} mb="xs" />
            <Skeleton width={300} height={30} mb="xs" />
            <Skeleton width={300} height={30} />
        </Card>
    );
}

export function PaymentForm({method}) {
    // Order ID info
    const { orderId, token } = useParams();

    // Create payment intent
    const {data} = useSWR(
        orderId && token && method ? ['order-payreq', orderId, token, method.value] : null,
        fetchData,
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        });

    // Loading state
    if (!data) return <Loading />;

    switch (method.value) {
        case 'stripe':
            return <StripeElement clientSecret={data.client_secret} transactionId={data.transaction_id} />;
        case 'icbc_cybersource':
            return <CyberSourceForm targetUrl={data.target_url} params={data.form_params} />;
        case 'offline':
            return <OfflinePaymentInfo bankInfo={data.bank_info} transactionId={data.transaction_id} />;
        default:
            return (<ErrorAlert error="Unsupported payment method" />);
    }
}