import {MantineTheme} from "@mantine/core";

const fontFamily = 'Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji';
const headingsFontFamily = 'Poppins, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji';

/**
 * @type {MantineTheme}
 */
export const theme = {
    colorScheme: 'light',
    colors: {
        primary: ['#dff4ff', '#b2dbff', '#83c2fb', '#55aaf9', '#2a92f5', '#1878dd', '#0d5eac', '#04437c', '#00284c', '#000e1e'],
        secondary: ['#e8f3fe', '#cbd7e8', '#abbdd4', '#8aa2c1', '#6a88af', '#506e95', '#3e5675', '#2b3d54', '#182534', '#040c17'],
        accent: ['#e4f6ff', '#c6dfea', '#a6c8d8', '#85b2c7', '#649cb6', '#4a829c', '#38657a', '#264858', '#122c37', '#001018'],
    },
    primaryColor: 'primary',
    // primaryShade: 5,
    // defaultRadius: 'md',
    components: {
        Title: {
            styles: {
                root: {
                    color: '#334863',
                }
            }
        },
        Button: {
            defaultProps: { radius: 'lg' }
        },
        Card: {
            defaultProps: { radius: 'lg', shadow: 'sm', withBorder: true },
        },
        Input: {
            defaultProps: { radius: 'md' },
        },
        Alert: {
            defaultProps: { radius: 'lg', variant: 'filled' },
        },
    },
    fontFamily: fontFamily,
    headings: {
        fontFamily: headingsFontFamily,
    },
    other: {
        headerHeight: 64,
    }
};