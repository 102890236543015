import {Stack, Loader, Text} from "@mantine/core";
import useSWR from "swr";
import api from "../lib/api";
import TransactionSuccessPage from "../components/order_details/transaction/success";
import TransactionIncompletePage from "../components/order_details/transaction/incomplete";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

async function fetchData(key, orderId, token, txnId) {
    return (await api.get(`/api/order/${orderId}/${token}/transaction/${txnId}`)).data.data;
}

export default function CheckTransactionPage() {
    const { t } = useTranslation();

    // Extract parameters from route
    const { orderId, token, txnId } = useParams();

    // Check transaction status
    const { data } = useSWR(orderId && token && txnId ? ['order-txn', orderId, token, txnId] : null, fetchData);

    if (!data) {
        return (
            <Stack align="center" py={32}>
                <Loader size={64} />
                <Text size="sm">{t('transaction_checking_message')}</Text>
            </Stack>
        );
    }

    if (data.status === 1) {
        return <TransactionSuccessPage data={data} />;
    }

    return <TransactionIncompletePage data={data} />;
}
