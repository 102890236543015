import {useContext} from "react";
import {OrderDetailsContext} from "./context";
import {
    ORDER_PAYMENT_STATUS_NOT_APPLICABLE,
    ORDER_PAYMENT_STATUS_PAID,
    ORDER_PAYMENT_STATUS_UNPAID, ORDER_PAYMENT_STATUS_WAITING_FOR_REVIEW
} from "../../lib/constants";
import {Alert, Box, Button, Group, Text, Title} from "@mantine/core";
import ResponseAcceptForm from "./response_accept";
import {usePaymentMethods} from "../../hooks/payment";
import {PaymentForm} from "./payment";
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation, useSearchParams} from "react-router-dom";
import {InfoCircle} from "tabler-icons-react";

function Unpaid() {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    const choosePaymentMethod = function (method) {
        return navigate(location.pathname + '?payment_method=' + method);
    }

    const { getMethod } = usePaymentMethods();
    if (searchParams.has('payment_method')) {
        const method = getMethod(searchParams.get('payment_method'));

        return (
            <Box mb="lg">
                <Title order={3}>{t('heading_payment')}</Title>
                <Text size="sm" mb="md">{t('payment_selected_description', { method: t(method?.label) })}</Text>

                <PaymentForm method={method} />
            </Box>
        );
    }

    return (
        <Box mb="lg">
            <Title order={3}>{t('heading_responding_quotation')}</Title>
            <Text size="sm" mb="md">{t('response_acknowledgement_accepted_unpaid')}</Text>

            {
                searchParams.has('error')
                    ? <Alert color="red" variant="filled" mb="md">{searchParams.get('error')}</Alert>
                    : null
            }

            <ResponseAcceptForm onSubmit={(e) => choosePaymentMethod(e.payment_method)} />
        </Box>
    );
}

function Paid() {
    const { t } = useTranslation();
    const order = useContext(OrderDetailsContext);

    return (
        <Box mb="lg">
            <Title order={3}>{t('heading_responding_quotation')}</Title>
            <Text size="sm" mb="md">
                <span dangerouslySetInnerHTML={{__html: t('response_acknowledgement_accepted_paid')}}></span>
            </Text>
            <Group grow spacing="xs">
                {/*<Button component="a" href={order.invoice_url} target="_blank" variant="outline">*/}
                {/*    {t('download_invoice')}*/}
                {/*</Button>*/}
                <Button component="a" href={order.receipt_url} target="_blank" variant="outline" >
                    <span dangerouslySetInnerHTML={{ __html: t('download_receipt') }} style={{ textAlign: 'center' }}></span>
                </Button>
            </Group>
        </Box>
    );
}

function WaitingForReview() {
    const { t } = useTranslation();

    return (
        <Box mb="lg">
            <Title order={3} mb="sm">{t('heading_responding_quotation')}</Title>
            <Alert variant="light" mb="md" icon={<InfoCircle />}>
                {t('response_acknowledgement_accepted_waiting_for_review')}
            </Alert>
        </Box>
    );
}

export function ResponseAccepted() {
    const order = useContext(OrderDetailsContext);
    switch (order.payment_status) {
        case ORDER_PAYMENT_STATUS_PAID:
        case ORDER_PAYMENT_STATUS_NOT_APPLICABLE:
            return <Paid />;
        case ORDER_PAYMENT_STATUS_UNPAID:
            return <Unpaid />;
        case ORDER_PAYMENT_STATUS_WAITING_FOR_REVIEW:
            return <WaitingForReview />;
    }
}