import {Button, Stack, Text, Title} from "@mantine/core";
import {CircleX as CircleXIcon} from "tabler-icons-react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

export default function TransactionIncompletePage({ data }) {
    const { t } = useTranslation();

    // Extract parameters from route
    const { orderId, token } = useParams();

    return (
        <Stack>
            <CircleXIcon size={72} color="red" />
            <Title>{t('transaction_result_incomplete_title')}</Title>
            <Text>{t('transaction_result_incomplete_message', { order_ref_no: data.order.ref_no })}</Text>
            <Text size="sm">{t('transaction_ref_no_label', { ref_no: data.ref_no })}</Text>

            <Button component="a" href={`/o/${orderId}/${token}`}>
                {t('transaction_result_continue')}
            </Button>
        </Stack>
    );
}