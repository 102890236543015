import {OverviewSection} from "../components/order_details/overview";
import {UnpaidItemsSection} from "../components/order_details/unpaid_items";
import {PaidItemsSection} from "../components/order_details/paid_items";
import {ResponseSection} from "../components/order_details/response";
import api from "../lib/api";
import {OrderDetailsContext} from "../components/order_details/context";
import LoadingScreen from "../components/order_details/loading";
import ErrorScreen from "../components/order_details/error";
import DeadlineAlert from "../components/order_details/deadline_alert";
import useSWR from 'swr';
import {useParams} from "react-router-dom";
import {DescriptionSection} from "../components/order_details/description";
import ItemSection from "../components/order_details/Item";

async function fetchOrder(path, orderId, token) {
    return (await api.get(`/api/order/${orderId}/${token}`)).data.data;
}

function OrderResponsePage() {
    // Fetch order data
    const { orderId, token } = useParams();
    const { data: order, error } = useSWR(orderId && token ? ['/api/order', orderId, token] : null, fetchOrder);

    if (error) return <ErrorScreen error={error.response.status === 404 ? 'error_messages.order_not_found' : error.toString()} />;
    if (!order) return <LoadingScreen />;

    return (
        <OrderDetailsContext.Provider value={order}>
            <DeadlineAlert />
            <OverviewSection />
            <ItemSection />
            <DescriptionSection />
            <ResponseSection />
        </OrderDetailsContext.Provider>
    );
}

export default OrderResponsePage;