import {Alert, Stack, Text, Title} from "@mantine/core";
import {AlertCircle as AlertCircleIcon} from "tabler-icons-react";
import {useTranslation} from "react-i18next";

export function ErrorMessage({error}) {
    const { t } = useTranslation();
    let message = error;
    if (typeof message === 'string' && message.startsWith('error_messages')) {
        message = t(message);
    }

    return <span dangerouslySetInnerHTML={{ __html: message }}></span>;
}

export default function ErrorScreen({error}) {
    const { t } = useTranslation();
    return (
        <Stack align="center" spacing="xs" p="xl">
            <AlertCircleIcon size={64} color="red" />
            <Title order={2}>{t('order_error_title')}</Title>
            <Text size="sm" align="center"><ErrorMessage error={error} /></Text>
        </Stack>
    );
}

export function ErrorAlert({error}) {
    return (
        <Alert color="red" icon={<AlertCircleIcon size={64} />}>
            <ErrorMessage error={error} />
        </Alert>
    );
}