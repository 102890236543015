import {UnstyledButton, Box, Group, Text, Title, Stack, createStyles} from "@mantine/core";
import {CircleCheck as CircleCheckIcon, AlertCircle as AlertCircleIcon, CircleX as CircleXIcon} from "tabler-icons-react";
import {useContext, useState} from "react";
import ResponseAcceptForm from "./response_accept";
import ResponseAmendForm from "./response_amend";
import ResponseRejectForm from "./response_reject";
import {OrderDetailsContext} from "./context";
import {
    ORDER_RESPONSE_STATUS_ACCEPTED,
    ORDER_RESPONSE_STATUS_AWAITING,
    ORDER_RESPONSE_STATUS_REJECTED_COLLECT,
    ORDER_RESPONSE_STATUS_REJECTED_SCRAPPED, ORDER_RESPONSE_STATUS_REQUEST_FOR_AMENDMENT
} from "../../lib/constants";
import ResponseResult from "./response_result";
import {ResponseAccepted} from "./response_result_accepted";
import {ErrorAlert} from "./error";
import api from "../../lib/api";
import {mutate} from "swr";
import {Trans, useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import ResponseAmendNotification from "./response_amend_notification";

const useStyles = createStyles((theme) => ({
    responseButton: {
        flex: 1,
        padding: '12px 4px',
        border: `2px solid ${theme.colors.secondary[4]}`,
        borderRadius: theme.radius.md,
        color: theme.colors.secondary[6],
        background: '#ffffff',
        textAlign: 'center',
        fontSize: '0.9em',
        transition: 'background 0.3s, border-color 0.3s',
        '&:hover': {
            background: theme.colors.gray[1],
        },
        minHeight: 125
    },
    active: {
        color: '#ffffff',
    },
    activeGreen: {
        borderColor: theme.colors.green[3],
        backgroundColor: theme.colors.green[5],
        '&:hover': {
            backgroundColor: theme.colors.green[6],
        }
    },
    activeOrange: {
        borderColor: theme.colors.orange[3],
        backgroundColor: theme.colors.orange[5],
        '&:hover': {
            backgroundColor: theme.colors.orange[6],
        }
    },
    activeRed: {
        borderColor: theme.colors.red[3],
        backgroundColor: theme.colors.red[5],
        '&:hover': {
            backgroundColor: theme.colors.red[6],
        }
    }
}));

function ResponseButton({icon, label, checked, activeClass, onClick}) {
    const { classes, cx } = useStyles();
    const isActive = checked;
    return (
        <UnstyledButton
            className={cx(classes.responseButton, {[activeClass]: isActive, [classes.active]: isActive})}
            onClick={onClick}
        >
            <Stack align="center" spacing="xs">
                {icon}
                <Trans i18nKey={label}>
                    {label}
                </Trans>
            </Stack>
        </UnstyledButton>
    );
}

function ResponseForm() {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const query = useParams();

    const [action, setAction] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const onSubmit = async function (data) {
        setSubmitting(true);
        setError(null);
        try {
            // Submit data through API
            const { orderId, token } = query;
            const res = await api.post(`/api/order/${orderId}/${token}/reply`, data);

            // Handle next action
            const resData = res.data;
            if (resData.next === 'refresh') {
                // Reload data
                await mutate(['/api/order', orderId, token]);
            } else if (resData.next === 'redirect') {
                // TODO: go to payment page
                console.log(resData.args);
                window.location = resData.args.url;
            }

        } catch (e) {
            setError(e.response?.data?.message || e.message || e.toString());
        }
        setSubmitting(false);
    }

    return (
        <Box mb="lg">
            <Title order={3}>{t('heading_responding_quotation')}</Title>
            <Text size="sm" mb="md">
                <div dangerouslySetInnerHTML={{ __html: t('response_description') }}></div>
            </Text>

            <Group grow position="center" spacing="xs" mb="lg">
                <ResponseButton
                    activeClass={classes.activeGreen}
                    icon={<CircleCheckIcon size={36} />}
                    label={t('response_actions.accept')}
                    checked={action === 'accept'}
                    onClick={() => setAction('accept')} />
                <ResponseButton
                    activeClass={classes.activeRed}
                    icon={<CircleXIcon size={36} />}
                    label={t('response_actions.reject')}
                    checked={action === 'reject'}
                    onClick={() => setAction('reject')} />
                <ResponseButton
                    activeClass={classes.activeOrange}
                    icon={<AlertCircleIcon size={36} />}
                    label={t('response_actions.request_for_amendment')}
                    checked={action === 'amend'}
                    onClick={() => setAction('amend')} />
            </Group>

            {error ? <Box mb="md"><ErrorAlert error={error} /></Box> : null}

            {action === 'accept' ? <ResponseAcceptForm onSubmit={onSubmit} submitting={submitting} /> : <></>}
            {/*{action === 'amend' ? <ResponseAmendForm onSubmit={onSubmit} submitting={submitting} /> : <></>}*/}
            {action === 'amend' ? <ResponseAmendNotification /> : <></>}
            {action === 'reject' ? <ResponseRejectForm onSubmit={onSubmit} submitting={submitting} /> : <></>}
        </Box>
    );
}

export function ResponseSection() {
    const order = useContext(OrderDetailsContext);
    const { t } = useTranslation();

    switch (order.response_status) {
        case ORDER_RESPONSE_STATUS_AWAITING:
            return <ResponseForm />
        case ORDER_RESPONSE_STATUS_REJECTED_COLLECT:
            return <ResponseResult text={(
                <>
                    <Text mb="sm">{t('response_acknowledgement_reject_collect')}</Text>

                    <Title order={6}>{t('address')}</Title>
                    <Text size="sm" mb="sm">{order.brand.address}</Text>

                    <Title order={6}>{t('business_hours')}</Title>
                    <Text size="sm">{order.brand.business_hours}</Text>
                </>
            )} />;
        case ORDER_RESPONSE_STATUS_REJECTED_SCRAPPED:
            return <ResponseResult text={t('response_acknowledgement_reject_scrapped')} />;
        case ORDER_RESPONSE_STATUS_REQUEST_FOR_AMENDMENT:
            return <ResponseResult text={t('response_acknowledgement_request_for_amendment')} />;
        case ORDER_RESPONSE_STATUS_ACCEPTED:
            return <ResponseAccepted />;
    }

    return <></>;
}