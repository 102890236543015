import {Paper, Title, Text} from "@mantine/core";
import {Trans, useTranslation} from "react-i18next";

export default function ResponseAmendNotification() {
    const { t } = useTranslation();
    return (
        <Paper p="sm" shadow="none" mt="xs" radius="md"
               sx={(theme) => ({backgroundColor: theme.colors.gray[1]})}>
            <Title order={6} size="sm" mb={8} style={{fontWeight: "bold"}}>
                <Trans i18nKey={"response_amend_notification.label"}></Trans>
            </Title>
            <Text size="sm">
                <div>
                    (852)<a href="tel:85230659666" style={{ textDecoration: "underline", color: '#6a88af' }}>3065 9666</a>(HK)<br/>
                    (853)<a href="tel:85368258567" style={{ textDecoration: "underline", color: '#6a88af' }}>6825 8567</a>(Macau)
                </div>
            </Text>
        </Paper>
    );
}