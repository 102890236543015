import {notifyError} from "../../lib/notification";

export function handleApiFormErrors(e, form) {
    // Bind form errors
    if (e?.response?.status === 422) {
        const errors = e?.response?.data?.errors;
        if (errors) {
            for (let field in errors) {
                if (form) {
                    form.setFieldError(field, errors[field].join(' '));
                } else {
                    notifyError(errors[field].join(' '));
                }
            }
        }
    } else if (typeof e?.response?.data?.message === 'string' && e.response.data.message.length > 0) {
        notifyError(e.response.data.message);
    } else if (typeof e?.message === 'string' && e.message.length > 0) {
        notifyError(e.message);
    } else {
        notifyError(e.toString());
    }
}