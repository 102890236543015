import {Button, Container, Group, Header, Menu} from "@mantine/core";
import Logo from "./Logo";
import {World as WorldIcon, ChevronDown as ChevronDownIcon} from "tabler-icons-react";

function LanguageBtn() {
    return (
        <Menu withArrow>
            <Menu.Target>
                <Button variant="subtle" color="dark"><WorldIcon /> <ChevronDownIcon size={16} /></Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item>Settings</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}

function AppHeader() {
    return (
        <Header height={64} withBorder={false} sx={(theme) => ({boxShadow: theme.shadows.sm})}>
            <Container>
                <Group position="apart" align="center" sx={{height: 64}}>
                    <Logo />
                    {/*<LanguageBtn />*/}
                </Group>
            </Container>
        </Header>
    );
}

export default AppHeader;