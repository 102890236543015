import {Button, Card, Divider, FileInput, Image, Text} from "@mantine/core";
import {useTranslation} from "react-i18next";
import { Upload as IconUpload } from 'tabler-icons-react';
import {useMemo, useState} from "react";
import useAsyncSubmit from "../../../hooks/submit";
import api from "../../../lib/api";
import {useParams} from "react-router-dom";

export default function OfflinePaymentInfo({bankInfo, transactionId}) {
    const {t} = useTranslation();
    const {orderId, token} = useParams();
    const [file, setFile] = useState(null);

    const {submitting, submitHandler} = useAsyncSubmit({
        onSubmit: async function () {
            // Check if file is selected and the file size is lte 8MB
            if (!file)
                throw t('upload_receipt_file_error_required');
            if (file.size > 8 * 1024 * 1024)
                throw t('upload_receipt_file_error_exceed_size');

            // Submit to API
            const formData = new FormData();
            formData.append('file', file);
            formData.append('order_id', orderId);
            formData.append('token', token);
            formData.append('txn_id', transactionId);

            return await api.post('payment-result/offline', formData);
        },
        onSubmitSuccess: function (data) {
            window.location = data.url;
        }
    });

    const imagePreviewUrl = useMemo(() => {
        return file ? URL.createObjectURL(file) : null;
    }, [file]);

    return (
        <form onSubmit={(e) => { e.preventDefault(); submitHandler(); }}>
            <Card p={0} mb="md">
                <Card.Section px="md">
                    <div dangerouslySetInnerHTML={{__html: bankInfo}}></div>
                </Card.Section>
                <Divider />
                <Card.Section px="md" py="md">
                    <Text mb="md">{t('offline_payment_upload_receipt_instruction')}</Text>

                    <FileInput
                        placeholder={t('receipt_file_placeholder')}
                        label={t('receipt_file_label')}
                        description={t('receipt_file_description')}
                        icon={<IconUpload />}
                        size="md" accept="image/png,image/jpeg"
                        withAsterisk
                        value={file} onChange={setFile}
                        mb="md"
                    />

                    {imagePreviewUrl
                        ? <Image src={imagePreviewUrl}
                                 imageProps={{ onLoad: () => URL.revokeObjectURL(imagePreviewUrl) }}
                                 fit="cover" width={150} height={150} radius="lg" />
                        : null}
                </Card.Section>
            </Card>

            <Button fullWidth type="submit" disabled={!file} loading={submitting}>
                {t('submit')}
            </Button>
        </form>
    );
}