import {Loader, Stack, Text, Title} from "@mantine/core";
import {useTranslation} from "react-i18next";

export default function LoadingScreen() {
    const {t} = useTranslation();

    return (
        <Stack align="center" spacing="xs" p="xl">
            <Loader size={64} />
            <Title order={2}>{t('order_loading_title')}</Title>
            <Text size="sm" align="center">{t('order_loading_message')}</Text>
        </Stack>
    );
}