import {Box, Card, Table, Text, Title} from "@mantine/core";
import {PaidItems} from "./paid_items";
import {UnpaidItems} from "./unpaid_items";
import {useTranslation} from "react-i18next";

export default function ItemSection() {
    const { t } = useTranslation();
    return (
        <Box mb="lg">
            <Title order={3}>{t('heading_order_items')}</Title>
            <Text mt={6} />
            <PaidItems />
            <Text mt={36} />
            <UnpaidItems />
        </Box>
    )
}