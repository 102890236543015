import {showNotification} from "@mantine/notifications";
import {Check as CheckIcon, AlertCircle as AlertCircleIcon} from "tabler-icons-react";

export function notifySuccess(title, message) {
    return showNotification({
        title: title,
        message: message,
        icon: <CheckIcon size={18} />,
        color: 'teal'
    });
}

export function notifyError(title, message) {
    return showNotification({
        title: title,
        message: message,
        icon: <AlertCircleIcon size={18} />,
        color: 'red'
    });
}