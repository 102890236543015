import {useTranslation} from "react-i18next";
import {Stack, Text, Title} from "@mantine/core";
import {AlertCircle as AlertCircleIcon} from "tabler-icons-react";
import {ErrorMessage} from "./order_details/error";

export default function Error404() {
    const { t } = useTranslation();
    return (
        <Stack align="center" spacing="xs" p="xl">
            <AlertCircleIcon size={64} color="red" />
            <Title order={2}>{t('error_page_not_found')}</Title>
            <Text size="sm" align="center"><ErrorMessage error={t('error_page_not_found_description')} /></Text>
        </Stack>
    );
}