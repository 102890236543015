import {AlertCircle as AlertCircleIcon} from "tabler-icons-react";
import {dateStringFormat} from "../../lib/format";
import {Alert} from "@mantine/core";
import {useContext} from "react";
import {OrderDetailsContext} from "./context";
import {ORDER_RESPONSE_STATUS_AWAITING, ORDER_RESPONSE_STATUS_EXPIRED} from "../../lib/constants";
import {useTranslation} from "react-i18next";

export default function DeadlineAlert() {
    const { t } = useTranslation();
    const order = useContext(OrderDetailsContext);

    switch (order.response_status) {
        // Awaiting user's response
        case ORDER_RESPONSE_STATUS_AWAITING:
            return (
                <Alert color="orange" icon={<AlertCircleIcon size={24} />} mb="xl">
                    {t('response_deadline_alert', {date: dateStringFormat(order.response_expiry_date)})}
                </Alert>
            );

        // Expired, but not replied
        case ORDER_RESPONSE_STATUS_EXPIRED:
            return (
                <Alert color="red" icon={<AlertCircleIcon size={24} />} mb="xl">
                    {t('response_already_expired')}
                </Alert>
            );

    }

    return (<></>);
}