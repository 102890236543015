import {useTranslation} from "react-i18next";
import {Box, Card, Title} from "@mantine/core";
import {useContext} from "react";
import {OrderDetailsContext} from "./context";

export function DescriptionSection() {
    const { t } = useTranslation();
    const order = useContext(OrderDetailsContext);

    if (!order.description)
        return (<></>);

    return (
        <Box mb="lg">
            <Title order={3}>{t('malfunction_description')}</Title>
            <Card>
                {order.description}
            </Card>
        </Box>
    );
}