import {Button, Card} from "@mantine/core";
import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import ErrorBoundary from "../../layout/ErrorBoundary";
import {ErrorAlert} from "../error";
import useAsyncSubmit from "../../../hooks/submit";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function StripeElement({ clientSecret, transactionId }) {
    // Language
    const { i18n } = useTranslation();

    let stripeLocale = {
        'zh-HK': 'zh-HK',
        'zh-CN': 'zh-CN',
        'en': 'en',
    }[i18n.resolvedLanguage] || 'en';


    const options = {
        // passing the client secret obtained in step 2
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {},
        locale: stripeLocale,
    };

    return (
        <ErrorBoundary fallback={(error) => <ErrorAlert error={error} />}>
            <Elements stripe={stripePromise} options={options}>
                <StripePaymentForm transactionId={transactionId} />
            </Elements>
        </ErrorBoundary>
    );
}

function StripePaymentForm({transactionId}) {
    // Language
    const { t } = useTranslation();

    // Router
    const query = useParams();

    /*
        Stripe element options
     */
    const stripe = useStripe();
    const elements = useElements();

    /*
     * Handle submission
     */
    const {submitting, submitHandler} = useAsyncSubmit({
        onSubmit: async function () {
            const baseURL = process.env.NEXT_PUBLIC_BASE_URL || window.location.origin;
            const result = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
                    return_url: `${baseURL}/o/${query.orderId}/${query.token}/txn/${transactionId}`,
                },
            });

            console.log(result);

            if (result.error) throw result.error;

            return result;
        },
        onSubmitSuccess: function () {
            console.log('onSubmitSuccess');
        }
    });

    return (
        <>
            <Card mb="md">
                <PaymentElement />
            </Card>
            <Button
                fullWidth type="submit" disabled={!stripe || !elements} loading={submitting}
                onClick={() => submitHandler()}
            >
                {t('submit')}
            </Button>
        </>
    );
}