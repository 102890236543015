export const typeLabel = (type) => {
    switch (type) {
        case "已收費":
            return "Paid 已收費";
        case "須收費":
            return "To be paid 須收費 ";
        default:
            return type

    }
}