import {Box, Button, Card, Group, Radio, Text} from "@mantine/core";
import {useForm} from "@mantine/form";
import {usePaymentMethods} from "../../hooks/payment";
import {useTranslation} from "react-i18next";
import {useContext, useMemo} from "react";
import {calculateOrderSum, OrderDetailsContext} from "./context";
import {moneyFormat} from "../../lib/format";

function PaymentMethodSelector({form}) {
    const { t } = useTranslation();
    const { methods: paymentMethods } = usePaymentMethods();
    const order = useContext(OrderDetailsContext);

    // Calculate total amount
    const sum = useMemo(() => {
        return order.total ? Number.parseFloat(order.total).toFixed(2) : calculateOrderSum(order)
    }, [order]);


    return (
        <Radio.Group orientation="vertical" spacing="xs" pb="xs" required
                     {...form.getInputProps('payment_method')}>
            {paymentMethods.map((p) => (
                <label key={p.value}>
                    <Group spacing="xs">
                        <Radio value={p.value} disabled={p.min_amount && sum < p.min_amount} label={!p.icon ? t(p.label) : null} />
                        {p.icon ? <img src={p.icon} alt={t(p.label)} height={36} /> : null}
                        {
                            p.min_amount && sum < p.min_amount
                            ? (<Text size="sm">{t('payment_method_below_min_amount', { amount: moneyFormat(p.min_amount) })}</Text>)
                            : null
                        }
                    </Group>
                </label>
            ))}
        </Radio.Group>
    );
}

export default function ResponseAcceptForm({onSubmit, submitting}) {
    const { t } = useTranslation();

    const form = useForm({
        initialValues: {
            payment_method: null,
        },
        validate: {
            payment_method: (value) => {
                return !value ? t('error_messages.payment_method_missing') : null;
            }
        }
    });

    // Calculate total amount
    const order = useContext(OrderDetailsContext);

    const sum = useMemo(() => {
        return order.total ? Number.parseFloat(order.total).toFixed(2) : calculateOrderSum(order)
    }, [order]);


    return (
        <form onSubmit={form.onSubmit((values) => onSubmit({action: 'accept', ...values}))}>
            <Box>
                <Text size="sm" mb="xs" dangerouslySetInnerHTML={{ __html: t('ask_payment_description', { amount: moneyFormat(sum) }) }}></Text>

                <Card mb="lg" py="xs">
                    <PaymentMethodSelector form={form} />
                </Card>

                <Button fullWidth type="submit" loading={submitting}>{t('next_step')}</Button>
            </Box>
        </form>
    );
}