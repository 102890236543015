import {Box, Card, createStyles, Grid, Group, Table, Text, Title, Alert} from "@mantine/core";
import {useContext, useMemo} from "react";
import {AlertCircle as AlertCircleIcon} from "tabler-icons-react";
import Badge from '../layout/Badge';
import {moneyFormat} from "../../lib/format";
import {calculateOrderSum, OrderDetailsContext} from "./context";
import {useTranslation} from "react-i18next";
import {typeLabel} from "../../hooks/item";

const useStyles = createStyles((theme) => ({
    priceCell: {
        textAlign: 'right !important'
    },
    grandTotalLabelCell: {
        textAlign: 'right !important'
    },
    tfoot: {
        backgroundColor: theme.colors.gray[0]
    }
}));

export function UnpaidItems() {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const order = useContext(OrderDetailsContext);

    // Order items
    const items = order.items.filter(item => item.type === "須收費");
    const tbody = items.map(function (item, k) {
        return (
            <tr key={k}>
                <td>
                    <Group align={'center'}>
                        <Text size="sm">{item.description}</Text>
                        {item.type ? (<Badge transformUpper={false} size="lg" color={item.type === "須收費" ? 'pink' : "indigo"} variant="filled">{typeLabel(item.type)}</Badge>) : null}
                    </Group>
                </td>
                <td className={classes.priceCell}>{moneyFormat((item.unit_price * item.quantity).toFixed(2))}</td>
            </tr>
        );
    });

    // Calculate total amount
    // const sum = useMemo(() => {
    //     return calculateOrderSum(order);
    // }, [order]);

    return (
        <Box mb="lg">

            <Text size="sm" mb="sm">{t('order_items_description')}</Text>

            {
                items.length > 0 ? (
                    <Card p={0}>
                        <Table verticalSpacing="xs">
                            <tbody>
                            {tbody}
                            </tbody>
                            <tfoot className={classes.tfoot}>
                            <tr>
                                <th className={classes.grandTotalLabelCell}>{t('grand_total')}</th>
                                <th className={classes.priceCell}>
                                    <Text color="primary" weight="bold" size="md">{moneyFormat(order.total)}</Text>
                                </th>
                            </tr>
                            </tfoot>
                        </Table>
                    </Card>
                ) : (
                    <Alert icon={<AlertCircleIcon size="1rem" />} color="cyan">
                        {t('order_items_empty')}
                    </Alert>
                )
            }


        </Box>
    );
}
