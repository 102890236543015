export const ORDER_RESPONSE_STATUS_AWAITING = 0;
export const ORDER_RESPONSE_STATUS_ACCEPTED = 1;
export const ORDER_RESPONSE_STATUS_REJECTED_COLLECT = 2;
export const ORDER_RESPONSE_STATUS_REJECTED_SCRAPPED = 3;
export const ORDER_RESPONSE_STATUS_EXPIRED = 4;
export const ORDER_RESPONSE_STATUS_REQUEST_FOR_AMENDMENT = 5;

export const ORDER_PAYMENT_STATUS_NOT_APPLICABLE = 0;
export const ORDER_PAYMENT_STATUS_PAID = 1;
export const ORDER_PAYMENT_STATUS_UNPAID = 2;
export const ORDER_PAYMENT_STATUS_WAITING_FOR_REVIEW = 3;