import {Box, Card, Title, Divider, createStyles, Group, Button} from "@mantine/core";
import {useContext} from "react";
import {OrderDetailsContext} from "./context";
import {dateStringFormat} from "../../lib/format";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
    row: {
        display: 'flex',
        padding: theme.spacing.sm,
        fontSize: '0.9em',
    },
    rowLabel: {
        width: '15em',
    },
    rowValue: {
        flex: 1,
    },
}));

export function OverviewSection() {
    const { t } = useTranslation();
    const { classes } = useStyles();
    const order = useContext(OrderDetailsContext);

    return (
        <Box mb="lg">
            <Group position={"apart"} align={"center"} mb="xs">
                <Title order={3} >{t('heading_order_no', {ref_no: order.ref_no})}</Title>
                <Button component="a" href={order.quotation_url} target="_blank" variant="outline">
                    <div style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{__html: t('download_quotation')}}></div>
                </Button>
            </Group>


            <Card p={0}>
                <div className={classes.row}>
                    <div className={classes.rowLabel}>{t('product_name')}</div>
                    <div className={classes.rowValue}>{order.product_name ?? "--"}</div>
                </div>
                <Divider />
                <div className={classes.row}>
                    <div className={classes.rowLabel}>{t('current_status')}</div>
                    <div className={classes.rowValue}>{t('response_status.'+order.response_status.toString())}</div>
                </div>
                <Divider />
                <div className={classes.row}>
                    <div className={classes.rowLabel}>{t('quotation_date')}</div>
                    <div className={classes.rowValue}>{dateStringFormat(order.created_at)}</div>
                </div>
            </Card>
        </Box>
    );
}
