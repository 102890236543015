import dayjs from "dayjs";

export function moneyFormat(value) {
    return new Intl.NumberFormat('zh-HK', {style: 'currency', currency: 'HKD'}).format(value);
}

export function dateStringFormat(str) {
    if (!str) return null;

    return dayjs(str).format('YYYY-MM-DD');
}