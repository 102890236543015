import {Card, Loader, Title, Text, Stack} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {useEffect, useRef} from "react";

export default function CyberSourceForm({targetUrl, params}) {
    const {t} = useTranslation();
    const formRef = useRef();
    useEffect(() => {
        // redirect to target URL
        formRef?.current?.submit();
    }, [formRef]);

    return (
        <Card>
            <Stack align="center" spacing="xs">
                <Loader size={64} />

                <Title order={4}>{t('form_based_provider.redirect_message')}</Title>
                <Text>{t('form_based_provider.do_not_close')}</Text>
            </Stack>

            <form method="post" action={targetUrl} ref={formRef}>
                {Object.keys(params || {}).map((k) => (
                    <input key={k} type="hidden" name={k} value={params[k]} />
                ))}
            </form>
        </Card>
    )
}