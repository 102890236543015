import {Box, Alert, Title} from "@mantine/core";
import {useTranslation} from "react-i18next";

export default function ResponseResult({text}) {
    const { t } = useTranslation();

    return (
        <Box mb="lg">
            <Title order={3}>{t('heading_responding_quotation')}</Title>
            <Alert variant="light">{text}</Alert>
        </Box>
    );
}