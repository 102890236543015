export function usePaymentMethods() {
    const methods = [
        // { value: 'stripe', icon: '/payment_methods/creditcard_h.svg', label: 'payment_methods_label.stripe', min_amount: 4, },
        { value: 'icbc_cybersource', icon: '/payment_methods/creditcard_h.svg', label: 'payment_methods_label.icbc_cybersource', min_amount: 1, },
        // { value: 'icbc_cybersource', icon: '/payment_methods/creditcard_h.svg', name: 'Credit Card' },
        // { value: 'payme', icon: '/payment_methods/payme_h.svg', name: 'PayMe' },
        // { value: 'wechatpay_hk', icon: '/payment_methods/wechatpay_hk_h.svg', name: 'WeChat Pay HK' },
        // { value: 'alipay_hk', icon: '/payment_methods/alipay_hk_h.svg', name: 'Alipay HK' },
        { value: 'offline', label: 'payment_methods_label.offline' },
    ];
    const getMethod = function (method) {
        return methods.find(i => i.value === method);
    }

    return {
        methods,
        getMethod
    };
}