import { Badge as MantineBadge } from "@mantine/core";
import React, { forwardRef } from "react";
import { cloneElement } from "react";

const Badge = forwardRef(({ sx, transformUpper, ...props }, ref) => {
    return cloneElement(<MantineBadge />, {
        sx: {
            ...sx,
            "text-transform": transformUpper ? "uppercase" : "none",
        },
        ref,
        ...props,
    });
});

Badge.displayName = "Badge";

export default Badge;