import {Stack, Loader, Text} from "@mantine/core";
import useSWR from "swr";
import api from "../lib/api";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import CyberSourceForm from "../components/order_details/payments/cybersource";
import {useEffect, useState} from "react";

export default function CreatePaymentPage() {
    const { t } = useTranslation();

    // Extract parameters from route
    const { orderId, token, txnId } = useParams();

    // Get payment data
    const [status, setStatus] = useState(true);
    const [resData, setResData] = useState({
        form_params: {},
        target_url: null,
        transaction_id: null
    });

    useEffect(() => {
        setStatus(true)
        api.post(`/api/order/${orderId}/${token}/transaction/${txnId}/pay`)
            .then((res) => {
                setResData(res.data)
                setStatus(false)
            }).catch((err) => {

            })
    }, []);

    return (
        <div>
            <Stack align="center" py={32}>
                <Loader size={64} />
                <Text size="sm">{t('transaction_pay_message')}</Text>
            </Stack>
            {
                !status && (
                <div style={{opacity: 0}}>
                    <CyberSourceForm params={resData.form_params} targetUrl={resData.target_url}/>
                </div>)
            }
        </div>

    );

}
