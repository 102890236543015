import {AppShell, Container} from "@mantine/core";
import AppHeader from "./_header";

function BaseLayout({children}) {
    return (
        <AppShell header={<AppHeader />} padding={0}
                  styles={(theme) => ({main: { backgroundColor: '#FAFAFA' }})}>
            <Container py="xl">
                {children}
            </Container>
        </AppShell>
    );
}

export default BaseLayout;