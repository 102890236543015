import {useState} from "react";
import {notifySuccess} from "../lib/notification";
import {handleApiFormErrors} from "../components/form/error";

export default function useAsyncSubmit({onSubmit, onSubmitSuccess, form}) {
    const [submitting, setSubmitting] = useState(false);
    const submitHandler = async function (data) {
        setSubmitting(true);

        try {
            // Submit data to server
            const res = await onSubmit(data);

            // Success message
            notifySuccess('Success', res.data.message);

            if (typeof onSubmitSuccess === 'function') {
                await onSubmitSuccess(res.data);
            }
        } catch (e) {
            handleApiFormErrors(e, form);
        }

        setSubmitting(false);
    }

    return {
        submitting,
        submitHandler
    };
}